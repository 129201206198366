<template>
    <div class="all">
        <MPHeader04 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
        <div class="content">
            <div class="txt-42 m-t-180 center">Privacy policy</div>
            <p class="txt-16 center m-t-12">Last updated: March 29, 2023</p>
            <p class="txt-21 m-t-100">This Privacy Policy describes how blucapsport.com (the “Site” or “we”) collects, uses,
                and
                discloses your Personal Information when you visit or make a purchase from the Site.</p>

            <div class="txt-42 m-t-40">Contact</div>
            <p class="txt-21 m-t-12">If you have any questions or concerns about this Privacy Policy or our privacy
                practices, please contact us at:</p>
            <p class="txt-21">Blucap</p>
            <p class="txt-21">Building 22, No. 1, Cangxing Street, Yuhang District, Hangzhou, Zhejiang Province, 311100</p>
            <p class="txt-21">Email: <a class="under">support@blucapsport.com</a></p>
            <div class="txt-42 m-t-40">Collecting Personal Information</div>
            <p class="txt-21 m-t-12">When you visit the Site, we collect certain information about your device, your
                interaction with the Site, and information necessary to process your purchases. We may also collect
                additional information if you contact us for customer support. In this Privacy Policy, we refer to any
                information about an identifiable individual (including the information below) as “Personal Information”.
                See the list below for more information about what Personal Information we collect and why.</p>

            <ul>
                <li>
                    <p class="txt-21">Device information</p>
                    <ul>
                        <li>
                            <p class="txt-21">Purpose of collection: to load the Site accurately for you, and to perform
                                analytics on Site usage to optimize our Site.</p>
                        </li>
                        <li>
                            <p class="txt-21">Source of collection: Collected automatically when you access our Site using
                                cookies, log files, web beacons, tags, or pixels .</p>
                        </li>
                        <li>
                            <p class="txt-21">Disclosure for a business purpose: shared with our processor Shopify.</p>
                        </li>
                        <li>
                            <p class="txt-21">Personal Information collected: version of web browser, IP address, time zone,
                                cookie information, what sites or products you view, search terms, and how you interact with
                                the Site.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p class="txt-21 m-t-12">Order information</p>
                    <ul>
                        <li>
                            <p class="txt-21">Purpose of collection: to provide products or services to you to fulfill our
                                contract, to process your payment information, arrange for shipping, and provide you with
                                invoices and/or order confirmations, communicate with you, screen our orders for potential
                                risk or fraud, and when in line with the preferences you have shared with us, provide you
                                with information or advertising relating to our products or services.</p>
                        </li>
                        <li>
                            <p class="txt-21">Source of collection: collected from you.</p>
                        </li>
                        <li>
                            <p class="txt-21">Disclosure for a business purpose: shared with our processor Shopify.</p>
                        </li>
                        <li>
                            <p class="txt-21">Personal Information collected: name, billing address, shipping address,
                                payment information (including credit card numbers), email address, and phone number.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p class="txt-21 m-t-12">Customer support information</p>
                    <ul>
                        <li>
                            <p class="txt-21">Purpose of collection: to provide customer support.</p>
                        </li>
                        <li>
                            <p class="txt-21">Source of collection: collected from you</p>
                        </li>
                    </ul>
                </li>
            </ul>

            <div class="txt-42 m-t-40">Sharing Personal Information</div>
            <p class="txt-21 m-t-12">We share your Personal Information with service providers to help us provide our
                services and fulfill our contracts with you, as described above. For example:</p>
            <ul>
                <li>
                    <p class="txt-21 m-t-12">We use Shopify to power our online store. You can read more about how Shopify
                        uses
                        your Personal Information here: <a class="under">https://www.shopify.com/legal/privacy</a>.</p>
                </li>
                <li>
                    <p class="txt-21">We may share your Personal Information to comply with applicable laws and regulations,
                        to respond to a subpoena, search warrant or other lawful request for information we receive, or to
                        otherwise protect our rights.</p>
                </li>
            </ul>


            <div class="txt-42 m-t-40">Behavioural Advertising</div>
            <p class="txt-21 m-t-12">As described above, we use your Personal Information to provide you with targeted
                advertisements or marketing communications we believe may be of interest to you. For example:</p>
            <ul>
                <li>
                    <p class="txt-21 m-t-12">We use Google Analytics to help us understand how our customers use the Site.
                        You can read more about how Google uses your Personal Information here:
                        <a class="under">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of
                        Google Analytics here:
                        <a class="under">https://tools.google.com/dlpage/gaoptout</a>.
                    </p>
                </li>
                <li>
                    <p class="txt-21">We use Shopify Audiences to help us show ads on other websites with our advertising
                        partners to buyers who made purchases with other Shopify merchants and who may also be interested in
                        what we have to offer. We also share information about your use of the Site, your purchases, and the
                        email address associated with your purchases with Shopify Audiences, through which other Shopify
                        merchants may make offers you may be interested in.</p>
                </li>
            </ul>
            <p class="txt-21 m-t-12">For more information about how targeted advertising works, you can visit the Network
                Advertising Initiative’s (“NAI”) educational page at
                <a class="under">https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
            </p>
            <p class="txt-21 m-t-12">You can opt out of targeted advertising by:</p>
            <ul>
                <li>
                    <p class="txt-21 m-t-12">FACEBOOK - <a class="under">https://www.facebook.com/settings/?tab=ads</a></p>
                </li>
                <li>
                    <p class="txt-21">GOOGLE - <a class="under">https://www.google.com/settings/ads/anonymous</a></p>
                </li>
            </ul>
            <p class="txt-21 m-t-12">Additionally, you can opt out of some of these services by visiting the Digital
                Advertising Alliance’s opt-out portal at: <a class="under">https://optout.aboutads.info/</a>.</p>

            <div class="txt-42 m-t-40">Using Personal Information</div>
            <p class="txt-21 m-t-12">We use your personal Information to provide our services to you, which includes:
                offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up
                to date on new products, services, and offers.</p>
            <div class="txt-42 m-t-40">Lawful basis</div>

            <p class="txt-21 m-t-12">Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of
                the European Economic Area (“EEA”), we process your personal information under the following lawful bases:
            </p>
            <ul>
                <li>
                    <p class="txt-21 m-t-12">Your consent;</p>
                </li>
                <li>
                    <p class="txt-21">The performance of the contract between you and the Site;</p>
                </li>
                <li>
                    <p class="txt-21">Compliance with our legal obligations;</p>
                </li>
                <li>
                    <p class="txt-21">To protect your vital interests;</p>
                </li>
                <li>
                    <p class="txt-21">To perform a task carried out in the public interest;</p>
                </li>
                <li>
                    <p class="txt-21">For our legitimate interests, which do not override your fundamental rights and
                        freedoms.</p>
                </li>
            </ul>

            <div class="txt-42 m-t-40">Retention</div>
            <p class="txt-21 m-t-12">When you place an order through the Site, we will retain your Personal Information for
                our records unless and until you ask us to erase this information. For more information on your right of
                erasure, please see the ‘Your rights’ section below.</p>

            <div class="txt-42 m-t-40">Automatic decision-making</div>
            <p class="txt-21 m-t-12">If you are a resident of the EEA, you have the right to object to processing based
                solely on automated decision-making (which includes profiling), when that decision-making has a legal effect
                on you or otherwise significantly affects you.</p>
            <p class="txt-21 m-t-12">We DO NOT engage in fully automated decision-making that has a legal or otherwise
                significant effect using customer data.</p>
            <p class="txt-21 m-t-12">Our processor Shopify uses limited automated decision-making to prevent fraud that does
                not have a legal or otherwise significant effect on you.</p>
            <p class="txt-21 m-t-12">Services that include elements of automated decision-making include:</p>
            <ul>
                <li>
                    <p class="txt-21 m-t-12">Temporary blacklist of IP addresses associated with repeated failed
                        transactions. This blacklist persists for a small number of hours.</p>
                </li>
                <li>
                    <p class="txt-21">Temporary blacklist of credit cards associated with blacklisted IP addresses. This
                        blacklist persists for a small number of days.</p>
                </li>
            </ul>

            <div class="txt-42 m-t-40">Your rights</div>
            <p class="txt-27 m-t-12">GDPR</p>
            <p class="txt-21 m-t-12">If you are a resident of the EEA, you have the right to access the Personal Information
                we hold about you, to port it to a new service, and to ask that your Personal Information be corrected,
                updated, or erased. If you would like to exercise these rights, please contact us through the contact
                information above.</p>
            <p class="txt-21 m-t-12">Your Personal Information will be initially processed in Ireland and then will be
                transferred outside of Europe for storage and further processing, including to Canada and the United States.
                For more information on how data transfers comply with the GDPR, see Shopify’s GDPR Whitepaper:
                <a class="under">https://help.shopify.com/en/manual/your-account/privacy/GDPR</a>.
            </p>

            <div class="txt-27 m-t-40">CCPA</div>
            <p class="txt-21 m-t-12">If you are a resident of California, you have the right to access the Personal
                Information we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to ask
                that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights,
                please contact us through the contact information above.
            </p>
            <p class="txt-21 m-t-12">If you would like to designate an authorized agent to submit these requests on your
                behalf, please contact us at the address above.</p>

            <div class="txt-42 m-t-40">Cookies</div>
            <p class="txt-21 m-t-12">A cookie is a small amount of information that’s downloaded to your computer or device
                when you visit our Site. We use a number of different cookies, including functional, performance,
                advertising, and social media or content cookies. Cookies make your browsing experience better by allowing
                the website to remember your actions and preferences (such as login and region selection). This means you
                don’t have to re-enter this information each time you return to the site or browse from one page to another.
                Cookies also provide information on how people use the website, for instance whether it’s their first time
                visiting or if they are a frequent visitor.
            </p>
            <p class="txt-21 m-t-12">We use the following cookies to optimize your experience on our Site and to provide our
                services.</p>
            <div class="txt-27 m-t-40">Cookies</div>

            <div>
                <table>
                    <thead>
                        <tr class="border-bottom">
                            <th class="t-w-1 txt-blod pading-14">Name</th>
                            <th class="t-w-2 txt-blod pading-14">Function</th>
                            <th class="t-w-3 txt-blod pading-14">Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="txt-21 pading-14">_ab</td>
                            <td class="txt-21 pading-14">Used in connection with access to admin.</td>
                            <td class="txt-21 pading-14">2y</td>
                        </tr>
                        <tr>
                            <td class="txt-21 pading-14">_secure_session_id</td>
                            <td class="txt-21 pading-14">Used in connection with navigation through a storefront.</td>
                            <td class="txt-21 pading-14">24h</td>
                        </tr>
                        <tr>
                            <td class="txt-21 pading-14">_shopify_country</td>
                            <td class="txt-21 pading-14">Used in connection with checkout.</td>
                            <td class="txt-21 pading-14">session</td>
                        </tr>
                        <tr>
                            <td class="txt-21 pading-14">_shopify_country</td>
                            <td class="txt-21 pading-14">Used in connection with checkout.</td>
                            <td class="txt-21 pading-14">session</td>
                        </tr>
                        <tr>
                            <td class="txt-21 pading-14">_shopify_country</td>
                            <td class="txt-21 pading-14">Used in connection with checkout.</td>
                            <td class="txt-21 pading-14">session</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="bg flex row-between row-center">
                <img src="../../assets/20230905/flex.svg" class="fimg">
                <div class="txt-21 txt001">暂时没时间，具体文案：https://blucapsport.com/en-cn/policies/privacy-policy</div>
            </div> -->

            <div class="txt-42 m-t-40">Complaints</div>
            <p class="txt-21 m-t-12">As noted above, if you would like to make a complaint, please contact us by e-mail or
                by mail using the details provided under “Contact” above.</p>
            <p class="txt-21 m-t-12">If you are not satisfied with our response to your complaint, you have the right to
                lodge your complaint with the relevant data protection authority. You can contact your local data protection
                authority, or us: <a class="under">support@blucapsport.com</a></p>


        </div>
        <div class="foot">
            <MPFooter04 />
        </div>
    </div>
</template>

<script>
// import $ from "jquery";
import MPHeader04 from "@/components/header/MPHeader04.vue"; //头部
import MPFooter04 from "@/components/footer/MPFooter04.vue"; //底部
export default {
    components: {
        MPHeader04,
        MPFooter04,
    },
    data() {
        return {
            changeClass1: 1,
            tabIndexs1: 0, //用来传值给子组件,来显示点击了第几个
        };
    },
    created() {
        //   这个用来在不同分辨率等比缩放css样式
        // $(function () {
        //   var w = window.screen.width;
        //   var zoom = w / 1920;
        //   $("#app").css({
        //     zoom: zoom,
        //     "-moz-transform": "scale(" + zoom + ")",
        //     "-moz-transform-origin": "top left",
        //   });
        // });
    },
    mounted() {
        //监听页面滚动事件
        // 要防抖
        window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
    },
    methods: {
        // 滑动
        handleScroll: function () {
            let top =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (top < 60) {
                this.changeClass1 = 1;
            } else {
                this.changeClass1 = 2;
            }
        },
        // 防抖函数
        debounce(fn, wait) {
            let timeout = null;
            return function () {
                if (timeout !== null) clearTimeout(timeout);
                timeout = setTimeout(fn, wait);
            };
        },
    },
};
</script>

<style lang="less" scoped>
.all {
    width: 100%;

    .foot {
        border-top: 0.01rem solid #303030;
        margin-top: 0.6rem;
    }

    .content {
        width: 3.33rem;
        margin: 0 auto;
        font-family: League Spartan;
        font-style: normal;
        color: #fff;

        .center {
            text-align: center;
        }

        .txt-42 {
            font-size: 0.42rem;
            font-weight: 500;
            line-height: normal;

        }

        .txt-27 {
            font-size: 0.27rem;
            font-weight: 500;
            line-height: 120%;
        }

        .txt-21 {
            font-size: 0.21rem;
            font-weight: 400;
            line-height: 120%;
        }

        .txt-16 {
            font-size: 0.16rem;
            font-weight: 400;
            line-height: 120%;
        }

        .m-t-180 {
            margin-top: 1.2rem;
        }

        .m-t-100 {
            margin-top: 0.34rem;
        }

        .m-t-40 {
            margin-top: 0.24rem;
        }

        .m-t-12 {
            margin-top: 0.12rem;
        }

        .under {
            text-decoration-line: underline;
            color: #249EFF;
        }

        .bg {
            width: 100%;
            background-color: #424242;
            padding-left: 0.2rem;
            padding-right: 0.2rem;
            padding-top: 0.14rem;
            padding-bottom: 0.14rem;
        }

        .txt001 {
            color: #000;
            word-wrap: break-word;
            width: 2.7rem;
        }
    }

    ul {
        list-style-type: disc;
        margin-left: 21px;
    }

    ul li::marker {
        color: rgba(255, 255, 255, 0.70);
        font-size: 16px;
    }

    .txt-blod {
        font-family: League Spartan;
        font-size: 0.21rem;
        font-style: normal;
        font-weight: 600;
        line-height: 152%;
        text-align: left;
    }

    .border-bottom {
        border-bottom: 0.01rem solid rgba(255, 255, 255, 0.70);

    }

    .t-w-1 {
        width: 1.03rem;
    }

    .t-w-2 {
        width: 1.03rem;
    }

    .t-w-3 {
        width: 1.03rem;

    }

    .pading-14 {
        padding-top: 0.14rem;
        padding-bottom: 0.14rem;
        padding-right: 0.14rem;
    }

}</style>