import { render, staticRenderFns } from "./PCPrivacy.vue?vue&type=template&id=68f4b153&scoped=true&"
import script from "./PCPrivacy.vue?vue&type=script&lang=js&"
export * from "./PCPrivacy.vue?vue&type=script&lang=js&"
import style0 from "./PCPrivacy.vue?vue&type=style&index=0&id=68f4b153&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f4b153",
  null
  
)

export default component.exports